<template>
  <layout-content-vertical-nav
    :nav-menu-items="navMenuItems"
    style="margin: 0!important;"
  >
    <slot></slot>
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3 mr12"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <app-title />
        </div>

        <div class="d-flex align-center right-row">
          <app-bar-hand-book></app-bar-hand-book>
          <!-- <app-bar-notification></app-bar-notification> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
        <vl-watermark v-if="name" :mark-text="name" />
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import AppBarHandBook from '@core/layouts/components/app-bar/AppBarHandBook.vue'
import LayoutContentVerticalNav from '@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import { mdiMenu, mdiHeartOutline } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { ref } from '@vue/composition-api'
import AppTitle from '@/layouts/vertical-nav/AppTitle.vue'
import { useStore } from '@/hooks/useStore'

export default {
  components: {
    AppTitle,
    LayoutContentVerticalNav,
    AppBarUserMenu,
    AppBarNotification,
    AppBarHandBook,
  },
  setup() {
    const { name, getUserInfo } = useStore('user')
    const $vuetify = getVuetify()
    const shallShowFullSearch = ref(false)

    // 如果不存在名字，则获取
    if (!name.value) {
      getUserInfo()
    }

    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    const navMenuItems = ref([])

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,
      shallShowFullSearch,
      name,
      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
